<template>
  <div class="flex-container non-scrollable">
    <div class="scrollable">
      <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid p-5">

        <span class="page-sub-title">{{ $t('admin.params.payroll.payrollDefinitionLabel') }}</span>

        <div class="flex flex-column mb-5 mt-3 gap-2">
          <div class="flex flex-row align-items-center">
            <label for="socialSecurityMaximalCap" class="w-4 block text-900 font-medium"> {{ $t('admin.params.payroll.socialSecurityMaximalCap') }}</label>
            <InputNumber id="socialSecurityMaximalCap" v-model="socialSecurityMaximalCap" class="w-3" mode="decimal"
                         :minFractionDigits="2" :maxFractionDigits="2" :suffix="` €`" highlightOnFocus/>
          </div>
          <div class="flex flex-row align-items-center">
            <label for="smic" class="w-4 block text-900 font-medium"> {{ $t('admin.params.payroll.smic') }}</label>
            <InputNumber id="smic" v-model="smic" class="w-3" mode="decimal" :minFractionDigits="2"
                         :maxFractionDigits="2" :suffix="` €`" highlightOnFocus/>
          </div>
          <div class="flex flex-row align-items-center">
            <label for="individualMutualCoefficient" class="w-4 block text-900 font-medium"> {{ $t('admin.params.payroll.individualMutualCoefficient') }}</label>
            <div class="flex flex-row align-items-center justify-content-between gap-3" :class="(individualMutualSalaryRateMarginAdaptation || !isNaN(individualMutualSalaryRate)) || (individualMutualEmployerRateMarginAdaptation || !isNaN(individualMutualEmployerRate)) ? 'mt-3' : ''">
              <InputNumber id="individualMutualCoefficient" v-model="individualMutualCoefficient" class="w-3" mode="decimal"
                           :minFractionDigits="4" :maxFractionDigits="4" suffix=" %" highlightOnFocus/>
              <div class="p-float-label w-3">
                <InputNumber id="individualMutualSalaryRate" v-model="individualMutualSalaryRate"
                             class="" mode="decimal" :min="0" :max="100" :minFractionDigits="4"
                             :maxFractionDigits="4" suffix=" %" highlightOnFocus
                             @focus="individualMutualSalaryRateMarginAdaptation = true"
                             @blur="individualMutualSalaryRateBlur"
                             @input="(event) => individualMutualEmployerRate = getRestPerCent(event.value)"/>
                <label for="individualMutualSalaryRate">Taux salarial</label>
              </div>
              <div class="p-float-label w-3">
                <InputNumber id="individualMutualEmployerRate" v-model="individualMutualEmployerRate"
                             mode="decimal" :min="0" :max="100" :minFractionDigits="4"
                             :maxFractionDigits="4" suffix=" %" highlightOnFocus
                             @focus="individualMutualEmployerRateMarginAdaptation = true"
                             @blur="individualMutualEmployerRateBlur"
                             @input="(event) => individualMutualSalaryRate = getRestPerCent(event.value)"/>
                <label for="individualMutualEmployerRate">Taux patronal</label>
              </div>
              <span class="">du</span>
              <Dropdown v-model="individualMutualBase" :options="getIndividualMutualBase"
                        option-label="label" class="w-6"/>
            </div>
          </div>
          <div class="flex flex-row align-items-center">
            <label for="familyMutualCoefficient" class="w-4 block text-900 font-medium"> {{ $t('admin.params.payroll.familyMutualCoefficient') }}</label>
            <div class="flex flex-row align-items-center justify-content-between gap-3" :class="(familyMutualSalaryRateMarginAdaptation || !isNaN(familyMutualSalaryRate)) || (familyMutualEmployerRateMarginAdaptation || !isNaN(familyMutualEmployerRate)) ? 'mt-3' : ''">
              <InputNumber id="familyMutualCoefficient" v-model="familyMutualCoefficient" class="w-3" mode="decimal"
                           :maxFractionDigits="4" suffix=" %" highlightOnFocus/>
              <div class="p-float-label w-3">
                <InputNumber id="familyMutualSalaryRate" v-model="familyMutualSalaryRate"
                             class="" mode="decimal" :min="0" :max="100" :minFractionDigits="4"
                             :maxFractionDigits="4" suffix=" %" highlightOnFocus
                             @focus="familyMutualSalaryRateMarginAdaptation = true"
                             @blur="familyMutualSalaryRateBlur"
                             @input="(event) => familyMutualEmployerRate = getRestPerCent(event.value)"/>
                <label for="familyMutualSalaryRate">Taux salarial</label>
              </div>
              <div class="p-float-label w-3">
                <InputNumber id="familyMutualEmployerRate" v-model="familyMutualEmployerRate"
                             mode="decimal" :min="0" :max="100" :minFractionDigits="4"
                             :maxFractionDigits="4" suffix=" %" highlightOnFocus
                             @focus="familyMutualEmployerRateMarginAdaptation = true"
                             @blur="familyMutualEmployerRateBlur"
                             @input="(event) => familyMutualSalaryRate = getRestPerCent(event.value)"/>
                <label for="familyMutualEmployerRate">Taux patronal</label>
              </div>
              <span class="">du</span>
              <Dropdown v-model="familyMutualBase" :options="getFamilyMutualBase"
                        option-label="label" class="w-6"/>
            </div>
          </div>
          <div class="flex flex-row align-items-center">
            <label for="socialSecurityEmployerRate" class="w-4 block text-900 font-medium"> {{ $t('admin.params.payroll.socialSecurityEmployerRate') }}</label>
            <InputNumber id="socialSecurityEmployerRate" v-model="socialSecurityEmployerRate" class="w-3" mode="decimal"
                         :minFractionDigits="4" :maxFractionDigits="4" suffix=" %" highlightOnFocus/>
          </div>
          <div class="flex flex-row align-items-center">
            <label for="csgCoefficient" class="w-4 block text-900 font-medium"> {{ $t('admin.params.payroll.csgCoefficient') }}</label>
            <InputNumber id="csgCoefficient" v-model="csgCoefficient" class="w-3" mode="decimal" :minFractionDigits="4"
                         :maxFractionDigits="4" suffix=" %" highlightOnFocus/>
          </div>
          <div v-if="companySettings.convention === constants.COLLECTIVE_CONVENTION.PORTAGE" class="flex flex-column">
            <label class="font-bold">Salaire de base</label>
            <div class="pl-2 pt-3">
              <div class="flex flex-row align-items-center gap-2">
                <RadioButton id="salaryBaseCoefficient" v-model="isSalaryBaseCoefficient" :value="true"/>
                <label class="font-bold">Coefficients:</label>
              </div>
              <div class="flex flex-column pl-5 pt-1 gap-2">
                <div class="flex flex-row align-items-center">
                  <label for="juniorCoefficientStatus" class="w-4 block text-900 font-medium"> {{ $t('admin.params.payroll.juniorCoefficient') }}</label>
                  <InputNumber id="juniorCoefficientStatus" v-model="juniorCoefficientStatus" class="w-3 mr-2"
                               mode="decimal" :minFractionDigits="2" :maxFractionDigits="4" highlightOnFocus/>
                  <span class="">du PMSS ({{ socialSecurityMaximalCap }} €)</span>
                </div>
                <div class="flex flex-row align-items-center">
                  <label for="seniorCoefficientStatus" class="w-4 block text-900 font-medium"> {{ $t('admin.params.payroll.seniorCoefficient') }}</label>
                  <InputNumber id="seniorCoefficientStatus" v-model="seniorCoefficientStatus" class="w-3 mr-2"
                               mode="decimal" :minFractionDigits="2" :maxFractionDigits="4" highlightOnFocus/>
                  <span class="">du PMSS ({{ socialSecurityMaximalCap }} €)</span>
                </div>
                <div class="flex flex-row align-items-center">
                  <label for="forfaitCoefficientStatus" class="w-4 block text-900 font-medium"> {{ $t('admin.params.payroll.forfaitCoefficient') }}</label>
                  <InputNumber id="forfaitCoefficientStatus" v-model="forfaitCoefficientStatus" class="w-3 mr-2"
                               mode="decimal" :minFractionDigits="2" :maxFractionDigits="4" highlightOnFocus/>
                  <span class="">du PMSS ({{ socialSecurityMaximalCap }} €)</span>
                </div>
              </div>
            </div>
            <div class="pl-2 pt-2">
              <div class="flex flex-row align-items-center gap-2">
                <RadioButton id="salaryBaseCoefficient" v-model="isSalaryBaseCoefficient" :value="false"/>
                <label class="font-bold">Montants:</label>
              </div>
              <div class="flex flex-column pl-5 pt-1 gap-2">
                <div class="flex flex-row align-items-center">
                  <label for="juniorFixedPriceStatus" class="w-4 block text-900 font-medium"> {{ $t('admin.params.payroll.juniorCoefficient') }}</label>
                  <InputNumber id="juniorFixedPriceStatus" v-model="juniorFixedPriceStatus" class="w-3" highlightOnFocus
                               mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" :suffix="` €`" />
                </div>
                <div class="flex flex-row align-items-center">
                  <label for="seniorFixedPriceStatus" class="w-4 block text-900 font-medium"> {{ $t('admin.params.payroll.seniorCoefficient') }}</label>
                  <InputNumber id="seniorFixedPriceStatus" v-model="seniorFixedPriceStatus" class="w-3" highlightOnFocus
                               mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" :suffix="` €`" />
                </div>
                <div class="flex flex-row align-items-center">
                  <label for="fixedPriceStatus" class="w-4 block text-900 font-medium"> {{ $t('admin.params.payroll.forfaitCoefficient') }}</label>
                  <InputNumber id="fixedPriceStatus" v-model="fixedPriceStatus" class="w-3" highlightOnFocus
                               mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" :suffix="` €`" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <span class="page-sub-title">{{ $t('admin.params.payroll.partialUnemploymentLabel') }}</span>

        <div class="flex flex-column mb-5 mt-3">
          <div class="flex flex-row align-items-center">
            <Checkbox id="payrollPartialUnemploymentOption" v-model="payrollPartialUnemploymentOption" class="mr-2" :binary="true" />
            <label for="payrollPartialUnemploymentOption">{{ $t('admin.params.payroll.partialUnemploymentLabel') }}</label>
          </div>
        </div>

        <span class="page-sub-title">{{ $t('admin.params.payroll.indemnityPayedVacancy') }}</span>

        <div class="flex flex-column mb-5 mt-3">
          <div class="flex flex-row align-items-center">
            <Checkbox id="indemnityPayedVacancy" v-model="indemnityPayedVacancy" class="mr-2" :binary="true" />
            <label for="indemnityPayedVacancy">{{ $t('admin.params.payroll.indemnityPayedVacancy') }}</label>
          </div>
        </div>

        <span class="page-sub-title">{{ $t('admin.params.payroll.workTime') }}</span>

        <div class="flex flex-row align-items-center mb-5 mt-3">
          <label for="workingHoursOnFullTime" class="w-4 block text-900 font-medium"> {{ $t('admin.params.payroll.workingHoursOnFullTime') }}</label>
          <InputNumber id="workingHoursOnFullTime" v-model="workingHoursOnFullTime" class="w-3" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" :suffix="` ${$t('hours')} / ${$t('month')}`" />
        </div>

        <span class="page-sub-title">{{ $t('admin.params.payroll.notificationsTitle') }}</span>

        <div class="flex flex-column mb-5 mt-3">
          <div class="flex flex-row align-items-center mt-3 mb-2">
            <Checkbox id="advStartInvoicingNotificationDateByEmail" v-model="payrollStartPayrollNotificationDateByEmail" class="mr-2" :binary="true" />
            <label for="advStartInvoicingNotificationDateByEmail" class="mr-4">{{ $t('admin.params.payroll.openPayrollNotificationDateByEmail') }}</label>

            <Checkbox id="advStartInvoicingNotificationDateOnEditor" v-model="payrollStartPayrollNotificationDateOnEditor" class="mr-2" :binary="true" />
            <label for="advStartInvoicingNotificationDateOnEditor">{{ $t('admin.params.payroll.openPayrollNotificationDateByEditor') }}</label>
          </div>
          <div class="flex flex-row align-items-center mb-2">
            <label for="advStartInvoicingNotificationDate" class="w-3 block text-900 font-medium"> {{ $t('admin.params.payroll.openPayrollNotificationDate') }}</label>
            <Dropdown
                id="advStartInvoicingNotificationDate"
                v-model="payrollStartPayrollNotificationDate"
                :disabled="!payrollStartPayrollNotificationDateByEmail && !payrollStartPayrollNotificationDateOnEditor"
                :options="payrollStartPayrollNotificationDateOptions"
                optionLabel="name"
                optionValue="value"
                placeholder="Selectionner une régularité"
            />
            <!--          <InputText id="advStartInvoicingNotificationDate" v-model="advStartInvoicingNotificationDate" :disabled="!advStartInvoicingNotificationDateByEmail && !advStartInvoicingNotificationDateOnEditor" type="text" class="w-3" />-->
          </div>
        </div>

        <span class="page-sub-title">{{ $t('admin.params.payroll.socialBenefitsTitle') }}</span>

        <div class="flex flex-column mb-5 mt-3">
          <div class="flex flex-row align-items-center mt-3 mb-2">
            <Checkbox id="payrollPeeOption" v-model="payrollPeeOption" class="mr-2" :binary="true" />
            <label for="payrollPeeOption" class="mr-4">{{ $t('admin.params.payroll.peeLabel') }}</label>
          </div>
          <div class="flex flex-row align-items-center mt-3 mb-2">
            <Checkbox id="payrollPercoOption" v-model="payrollPercoOption" class="mr-2" :binary="true" />
            <label for="payrollPercoOption">{{ $t('admin.params.payroll.percoLabel') }}</label>
          </div>

          <div class="flex flex-row align-items-center mt-3 mb-2">
            <Checkbox id="payrollRemoteWorkOption" v-model="payrollRemoteWorkOption" class="mr-2" :binary="true" />
            <label for="payrollRemoteWorkOption">{{ $t('admin.params.payroll.remoteWorkLabel') }}</label>
          </div>
          <div v-if="payrollRemoteWorkOption" class="flex flex-row align-items-center">
            <label for="payrollRemoteWorkFixedPrice" class="w-2 block text-900 font-medium"> {{ $t('admin.params.payroll.remoteWorkFixedPriceLabel') }}</label>
            <InputNumber id="payrollRemoteWorkFixedPrice" v-model="payrollRemoteWorkFixedPrice" class="w-3" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" suffix=" €" />
          </div>

          <div class="flex flex-row align-items-center mt-5 mb-2">
            <Checkbox id="payrollMajorMovementsOption" v-model="payrollMajorMovementsOption" class="mr-2" :binary="true" />
            <label for="payrollMajorMovementsOption">{{ $t('admin.params.payroll.majorMovementsOptionLabel') }}</label>
          </div>
          <div v-if="payrollMajorMovementsOption" class="flex flex-row align-items-center">
            <label for="payrollMajorMovementsDuration" class="w-2 block text-900 font-medium"> {{ $t('admin.params.payroll.majorMovementsDurationLabel') }}</label>
            <InputNumber id="payrollMajorMovementsDuration" v-model="payrollMajorMovementsDuration" class="w-3" suffix=" Jours" />
          </div>

          <div class="flex flex-row align-items-center mt-5 mb-2">
            <label for="payrollLunchFixedPrice" class="w-2 block text-900 font-medium"> {{ $t('admin.params.payroll.lunchFixedPriceLabel') }}</label>
            <InputNumber id="payrollLunchFixedPrice" v-model="payrollLunchFixedPrice" class="w-3" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" suffix=" €" />
          </div>
          <div class="flex flex-row align-items-center mt-3 mb-2">
            <label for="payrollDinnerFixedPrice" class="w-2 block text-900 font-medium"> {{ $t('admin.params.payroll.dinnerFixedPriceLabel') }}</label>
            <InputNumber id="payrollDinnerFixedPrice" v-model="payrollDinnerFixedPrice" class="w-3" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" suffix=" €" />
          </div>

          <div class="w-8 card mt-3">
            <p class="font-bold underline">Forfaits Hôtels :</p>
            <DataTable
                ref="dt" :value="payrollHotelFixedPrices" v-model:selection="payrollSelectedHotelFixedPrices" dataKey="id"
                responsiveLayout="scroll" :scrollable="true" scrollHeight="400px"
            >
              <Column selectionMode="multiple" :exportable="false"></Column>
              <Column field="label" header="Libellé" :sortable="true">
                <template #body="slotProps">
                  <InputText v-model="slotProps.data.label" placeholder="Libellé" />
                </template>
              </Column>
              <Column field="fixedPrice" header="Forfait Hotel" :sortable="true">
                <template #body="slotProps">
                  <InputNumber v-model="slotProps.data.fixedPrice" class="w-3" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" suffix=" €" placeholder="Forfait"/>
                </template>
              </Column>

              <Column :exportable="false">
                <template #body="slotProps">
                  <!--              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editProduct(slotProps.data)" />-->
                  <Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="deleteHotelFixedPrice(slotProps.data)" />
                </template>
              </Column>
            </DataTable>

            <Toolbar class="mt-2 p-2">
              <template #start>
                <Button label="Ajouter une ligne" icon="pi pi-plus" class="p-button-success mr-2 w-full" @click="addHotelFixedPrice" />
                <Button label="Supprimer" icon="pi pi-trash" class="p-button-danger" @click="deleteSelectedHotelFixedPrice" :disabled="!payrollSelectedHotelFixedPrices || !payrollSelectedHotelFixedPrices.length" />
              </template>
            </Toolbar>
          </div>
        </div>

        <span class="page-sub-title">{{ $t('admin.params.payroll.salarySimulationBulletinTitle') }}</span>
<!--        <iframe v-if="salarySheetSrc !== null" width="100%" height="1200px" :src="salarySheetSrc"/>-->

        <div class="flex justify-content-around align-items-center">

          <div class="card mt-3">
            <!--          @cell-edit-complete="onCellEditComplete"
                          @row-select-all="selectAllSalaryLines"
                          @row-unselect-all="deselectAllSalaryLines"
            -->
            <DataTable
                ref="salaryLines" :value="salaryLines" v-model:expandedRowGroups="expandedRowGroups"
                v-model:selection="linesToShow" v-model:editingRows="editingRows"
                dataKey="id" rowGroupMode="subheader" groupRowsBy="group" editMode="row" class="operation-members-table"
                showGridlines expandableRowGroups
                @row-edit-save="onRowEditSave"
            >
              <Column field="group" key="group" header="Group"></Column>
              <Column key="checkbox" headerStyle="width: 3em">
                <template #header>
                  <Checkbox v-model="selectAllLineChecked" type="text" binary @change="selectAllChanged"/>
                </template>
                <template #body="slotProps">
                  <div :style="slotProps.data.useRowSelection === false ? 'display: none;' : ''">
                    <Checkbox v-model="slotProps.data.selectedLine" type="text" binary @change="addToLinesToShow(slotProps.data)"/>
                  </div>
                </template>
              </Column>
              <Column field="domain" key="domain" header="RUBRIQUES">
                <template #body="slotProps">
                  {{ slotProps.data.domain }}
                </template>
                <template #editor="{ data, field }">
                  <InputText
                    v-if="data?.editableDomain"
                    v-model="data[field]" autofocus
                    :disabled="!data?.editableDomain" />
                  <span v-else>
                    {{ data[field] }}
                  </span>
                </template>
              </Column>
              <Column field="base" key="base" header="BASE">
                <template #body="slotProps">
                  {{ slotProps.data?.base?.label }}
                </template>
                <template #editor="{ data, field }">
                  <Dropdown
                    v-if="!data.disabledBase"
                    :ref="data.id + 'base'"
                    v-model="data[field]"
                    :options="getBaseOptions(data)"
                    :disabled="data.disabledBase"
                    option-label="label"
                  />
                  <!--                    @change="(event) => onChangeBase(event, data.id + 'base')"-->
                </template>
              </Column>
              <Column field="salaryRate" key="salaryRate" header="TAUX SALARIAL">
                <template #body="slotProps">
                  {{ !slotProps.data.salaryRate && slotProps.data.salaryRate !== 0 ? '' : `${slotProps.data.salaryRate} %` }}
                </template>
                <template #editor="{ data, field }">
                  <InputNumber
                      v-if="data.salaryRate !== null"
                      v-model="data[field]"
                      style="width: 70px;"
                      :input-style="{width: '30%'}"
                      autofocus
                      :min="0"
                      :minFractionDigits="4"
                      :maxFractionDigits="4"
                      locale="fr-FR"
                      suffix="%"
                  />
                </template>
              </Column>
              <!--            <Column field="salaryAmount" key="salaryAmount" header="MONTANT SALARIAL">-->
              <!--              <template #body="slotProps">-->
              <!--                {{ slotProps.data.salaryAmount }}-->
              <!--              </template>-->
              <!--            </Column>-->
              <Column field="employerAmount" key="employerAmount" header="TAUX PATRONAL">
                <template #body="slotProps">
                  {{ !slotProps.data.employerAmount && slotProps.data.employerAmount !== 0 ? '' : `${slotProps.data.employerAmount} %` }}
                </template>
                <template #editor="{ data, field }">
                  <InputNumber
                      v-if="data.employerAmount !== null"
                      v-model="data[field]"
                      style="width: 70px;"
                      :input-style="{width: '30%'}"
                      autofocus
                      :min="0"
                      :minFractionDigits="4"
                      :maxFractionDigits="4"
                      locale="fr-FR"
                      suffix="%"
                  />
                </template>
              </Column>
              <Column :rowEditor="true" style="width:10%; min-width:4rem" bodyStyle="text-align:center" class="row-editing-cell"/>
              <Column key="checkbox" headerStyle="width: 3em">
                <template #body="slotProps">
                  <div :style="!slotProps.data.isDeletable ? 'display: none;' : ''">
                    <Button class="p-button-rounded p-button-text text-red-300" icon="pi pi-trash" @click="askForDeleteSalaryLine(slotProps.data)"/>
                  </div>
                </template>
              </Column>
              <template #groupheader="slotProps">
                <span class="mr-3">{{slotProps.data.group}}</span>
                <Button v-if="slotProps.data.addLineEnable" label="Ajouter une ligne" class="w-2 p-button-sm row-add-pay" @click="addNewSalaryLine(slotProps.data)"/>
              </template>
            </DataTable>
          </div>
          <iframe v-if="salarySheetSrc !== null" width="45%" height="1200px" :src="salarySheetSrc"/>
        </div>

        <!-- To prevent Button overlap editable content -->
        <div class="w-full h-5rem"/>

        <div v-if="hasAccess($route.name, true)" class="float-button">
          <Button :label="$t('admin.params.company.registerButton')" type="submit" class="w-full p-3" :disabled="disableSaving"/>
        </div>

        <FileUpload ref="sourceUploader" invalidFileLimitMessage="Le nombre de fichiers est limité à {0}"
                    choose-label="Sélectionner" upload-label="Téléverser" cancel-label="Annuler"
                    custom-upload :file-limit="1" accept=".csv"
                    @uploader="uploadCSV">
          <template #empty>
            <p>Ajoutez votre fichier CSV ici.</p>
          </template>
        </FileUpload>
      </form>
    </div>
    <Dialog v-model:visible="deleteSalaryLinesDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span>Etes vous sur(e) de vouloir supprimer ces lignes ?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteSalaryLinesDialog = false"/>
        <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedSalaryLines" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {mapState} from "vuex";
import Alert from "@/utils/Alert";
import { getSalarySheetData, extractSalaryOperationMembers } from "@/utils/SalarySheetData";
import PdfGenerator from "@/utils/PdfGenerator";
import {disablePComponentsOfCurrentPage, getBasesFromOptions, getRestPerCent, hasAccess} from "@/utils/Misc";
import {csvToJson} from "@/utils/CsvLoader";

export default {
  setup: () => ({ v$: useVuelidate() }),
  name: "Paie",
  components: {},
  data() {
    return {
      hasAccess: hasAccess,
      payrollRestaurantTickets: false,
      payrollRestaurantAmount: 0,
      payrollRestaurantSalaryRate: 0,
      payrollRestaurantEmployerRate: 0,

      payrollStartPayrollNotificationDate: '',
      payrollStartPayrollNotificationDateByEmail: false,
      payrollStartPayrollNotificationDateOnEditor: false,
      payrollStartPayrollNotificationDateOptions: [
        { name: 'Tous les 20 jours', value: '20' },
        { name: 'Tous les 25 jours', value: '25' },
        { name: 'Tous les 30 jours', value: '30' }
      ],

      commercialFinancialReserve: {
        commercialFinancialReserveRateJunior: 0,
        commercialFinancialReserveRateSenior: 0,
        commercialFinancialReserveRateOthers: 0
      },

      payrollPeeOption: false,
      payrollPercoOption: false,
      payrollPartialUnemploymentOption: false,
      indemnityPayedVacancy: false,

      workingHoursOnFullTime: 0,

      socialSecurityMaximalCap: 0,
      smic: 0,

      individualMutualCoefficient: 0,
      individualMutualSalaryRate: 0,
      individualMutualEmployerRate: 0,
      individualMutualBase: {},
      individualMutualSalaryRateMarginAdaptation: false,
      individualMutualEmployerRateMarginAdaptation: false,

      familyMutualCoefficient: 0,
      familyMutualSalaryRate: 0,
      familyMutualEmployerRate: 0,
      familyMutualBase: {},
      familyMutualSalaryRateMarginAdaptation: false,
      familyMutualEmployerRateMarginAdaptation: false,

      socialSecurityEmployerRate: 0,
      csgCoefficient: 0,

      isSalaryBaseCoefficient: true,

      juniorCoefficientStatus: 0,
      seniorCoefficientStatus: 0,
      forfaitCoefficientStatus: 0,

      juniorFixedPriceStatus: 0,
      seniorFixedPriceStatus: 0,
      fixedPriceStatus: 0,

      payrollRemoteWorkOption: false,
      payrollRemoteWorkFixedPrice: 0,

      payrollMajorMovementsOption: false,
      payrollMajorMovementsDuration: 0,

      payrollLunchFixedPrice: 0,
      payrollDinnerFixedPrice: 0,

      payrollHotelFixedPrices: [
        { label: 'Forfait Hotel (1)', fixedPrice: 0 }
      ],
      payrollSelectedHotelFixedPrices: [],
      lastHotelFixedPriceIdSet: null,

      salaryLines: [],
      showSalaryLine: true,
      expandedRowGroups: null,
      linesToShow: [],
      editingRows: [],
      selectAllLineChecked: false,
      salarySheetSrc: null,

      deleteSalaryLinesDialog: false,

      submitted: false,
      disableSaving: false,
      loaded: false,

      csvResultData: [],
    }
  },
  async created() {
    // get only company settings ?
    await this.$store.dispatch('misc/getAllSettings', {})
    await this.initializeFields()
    this.loaded = true
  },
  computed: {
    ...mapState({
      settings: state => state.misc.settings.Paie,
      companySettings: state => state.misc.settings.Entreprise,
      commercialSettings: state => state.misc.settings.Commercial,
      constants: state => state.constants
    }),
    getIndividualMutualBase () {
      return getBasesFromOptions([
        this.constants.BASE_TYPES.PMSS,
        this.constants.BASE_TYPES.BRUT_SALARY
      ], [], this.constants.data.baseOptions)
    },
    getFamilyMutualBase () {
      return getBasesFromOptions([
        this.constants.BASE_TYPES.PMSS,
        this.constants.BASE_TYPES.BRUT_SALARY
      ], [], this.constants.data.baseOptions)
    }
  },
  watch: {
    loaded () {
      if (this.loaded) {
        this.$nextTick(() => {
          if (!hasAccess(this.$route.name, true)) disablePComponentsOfCurrentPage(document)
        })
      }
    }
  },
  validations() {
    return {
      // todo: later on describe the validation precisely, if so don't forget "*" after each require fields
    }
  },
  methods: {
    getBasesFromOptions,
    getRestPerCent,
    async initializeFields() {
      this.salaryLines = getSalarySheetData({ pay: this.settings, company: this.companySettings}, this.constants)
      this.expandAllGroupRow()
      this.generateSalarySlip()
      this.showDefaultAllCheckedValue()

      let hotelFixedPriceId = 1
      this.payrollHotelFixedPrices = this.settings.avantagesSociaux.forfaitHotels.map(hotelFixedPrice => {
        const hotelFixedPriceWithId = {
          ...hotelFixedPrice,
          id: hotelFixedPriceId,
          fixedPrice: hotelFixedPrice.fixedPrice ? (hotelFixedPrice.fixedPrice / 100) : 0
        }
        ++hotelFixedPriceId
        return hotelFixedPriceWithId
      })
      this.lastBankAccountIdSet = hotelFixedPriceId

      this.commercialFinancialReserve.commercialFinancialReserveRateJunior = this.commercialSettings.tauxReserveFinanciere.junior
      this.commercialFinancialReserve.commercialFinancialReserveRateSenior = this.commercialSettings.tauxReserveFinanciere.senior
      this.commercialFinancialReserve.commercialFinancialReserveRateOthers = this.commercialSettings.tauxReserveFinanciere.autres

      // this.payrollRestaurantTickets = this.settings.ticketsRestaurant.value // just an id
      // this.payrollRestaurantAmount = this.settings.ticketsRestaurant.amount
      // this.payrollRestaurantSalaryRate = this.settings.ticketsRestaurant.salaryRate
      // this.payrollRestaurantEmployerRate = this.settings.ticketsRestaurant.employerRate

      this.payrollStartPayrollNotificationDate = this.settings.notifications.dateOuverturePaie
      this.payrollStartPayrollNotificationDateByEmail = this.settings.notifications.email
      this.payrollStartPayrollNotificationDateOnEditor = this.settings.notifications.kaizen

      this.socialSecurityMaximalCap = !this.settings.paieDefinition?.socialSecurityMaximalCap ? 0 : this.settings.paieDefinition?.socialSecurityMaximalCap / 100
      this.smic = !this.settings.paieDefinition?.smic ? 0 : this.settings.paieDefinition?.smic / 100

      this.individualMutualCoefficient = this.settings.paieDefinition?.individualMutualCoefficient ? this.settings.paieDefinition?.individualMutualCoefficient * 100 : 0
      this.individualMutualSalaryRate = this.settings.paieDefinition?.individualMutualSalaryRate ? this.settings.paieDefinition?.individualMutualSalaryRate * 100 : 50
      this.individualMutualEmployerRate = this.settings.paieDefinition?.individualMutualEmployerRate ? 100 - (this.settings.paieDefinition?.individualMutualEmployerRate * 100) : 50
      this.individualMutualBase = this.settings.paieDefinition?.individualMutualBase ?? this.getIndividualMutualBase[0]

      this.familyMutualCoefficient = this.settings.paieDefinition?.familyMutualCoefficient ? this.settings.paieDefinition?.familyMutualCoefficient * 100 : 0
      this.familyMutualSalaryRate = this.settings.paieDefinition?.familyMutualSalaryRate ? this.settings.paieDefinition?.familyMutualSalaryRate * 100 : 50
      this.familyMutualEmployerRate = this.settings.paieDefinition?.familyMutualEmployerRate ? 100 - (this.settings.paieDefinition?.familyMutualEmployerRate * 100) : 50
      this.familyMutualBase = this.settings.paieDefinition?.familyMutualBase ?? this.getFamilyMutualBase[0]

      this.socialSecurityEmployerRate = this.settings.paieDefinition?.socialSecurityEmployerRate ? this.settings.paieDefinition?.socialSecurityEmployerRate * 100 : 0
      this.csgCoefficient = this.settings.paieDefinition?.csgCoefficient ? this.settings.paieDefinition?.csgCoefficient * 100 : 0

      this.isSalaryBaseCoefficient = this.settings.paieDefinition?.isSalaryBaseCoefficient ?? true

      this.juniorCoefficientStatus = this.settings.paieDefinition?.juniorCoefficientStatus ?? 0.7
      this.seniorCoefficientStatus = this.settings.paieDefinition?.seniorCoefficientStatus ?? 0.75
      this.forfaitCoefficientStatus = this.settings.paieDefinition?.forfaitCoefficientStatus ?? 0.7

      this.juniorFixedPriceStatus = this.settings.paieDefinition?.juniorFixedPriceStatus / 100 ?? 0
      this.seniorFixedPriceStatus = this.settings.paieDefinition?.seniorFixedPriceStatus / 100 ?? 0
      this.fixedPriceStatus = this.settings.paieDefinition?.fixedPriceStatus / 100 ?? 0

      this.payrollPeeOption = this.settings.avantagesSociaux.pee
      this.payrollPercoOption = this.settings.avantagesSociaux.perco
      this.payrollPartialUnemploymentOption = this.settings.avantagesSociaux.chomagePartiel
      this.indemnityPayedVacancy = this.settings.avantagesSociaux.indemnitesCongesPayes

      this.workingHoursOnFullTime = this.settings.workTime.workingHoursOnFullTime

      this.payrollRemoteWorkOption = this.settings.avantagesSociaux.teletravail.value
      this.payrollRemoteWorkFixedPrice = this.settings.avantagesSociaux.teletravail.forfait / 100

      this.payrollMajorMovementsOption = this.settings.avantagesSociaux.forfaitGrandsDeplacements.value
      this.payrollMajorMovementsDuration = this.settings.avantagesSociaux.forfaitGrandsDeplacements.duree

      this.payrollLunchFixedPrice = this.settings.avantagesSociaux.forfaitRepasMidi / 100
      this.payrollDinnerFixedPrice = this.settings.avantagesSociaux.forfaitRepasSoir / 100
    },
    uploadCSV (csv) {
      const filename = csv.files[0].name.split('.')[0]
      csvToJson(csv, async (jsonResult) => {
        this.csvResultData = jsonResult
        const typeFileName = 'import_source_'
        switch (filename) {
          case `${typeFileName}portes`:
            // console.log('import porte')
            await this.$store.dispatch('independent/importSourceData', jsonResult)
            break
          case `${typeFileName}missions`:
            // console.log('import porte')
            await this.$store.dispatch('mission/importSourceData', jsonResult)
            break
          case `${typeFileName}clients`:
            // console.log('import porte')
            await this.$store.dispatch('client/importSourceData', jsonResult)
            break
          case `${typeFileName}contacts`:
            // console.log('import porte')
            await this.$store.dispatch('contact/importSourceData', jsonResult)
            break
          case `${typeFileName}salaire_de_base`:
            // console.log('import porte')
            await this.$store.dispatch('independent/importIndependentBaseSalary', jsonResult)
            break
          case `${typeFileName}portes_dates`:
            // console.log('import porte')
            await this.$store.dispatch('independent/importIndependentDates', jsonResult)
            break
          default:
            console.log('Aucun import spécifique')
            break
        }
      })
      this.$refs.sourceUploader.clear();
      this.$refs.sourceUploader.uploadedFileCount = 0;
    },
    async handleSubmit(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        Alert.errorMessage(this, 'fieldRequired', {symbole: '(*)'})
        return
      }

      // todo: not good to re-get every update from the back and update the all json
      try {
        const payload = {
          // Commercial: {
          //   tauxReserveFinanciere: {
          //     junior: this.commercialFinancialReserve.commercialFinancialReserveRateJunior,
          //     senior: this.commercialFinancialReserve.commercialFinancialReserveRateSenior,
          //     autres: this.commercialFinancialReserve.commercialFinancialReserveRateOthers,
          //   }
          // },

          // ticketsRestaurant: {
          //   value: this.payrollRestaurantTickets,
          //   amount: this.payrollRestaurantAmount,
          //   salaryRate: this.payrollRestaurantSalaryRate,
          //   employerRate: this.payrollRestaurantEmployerRate
          // },

          Paie: {
            paieDefinition: {
              socialSecurityMaximalCap: this.socialSecurityMaximalCap * 100,
              smic: this.smic * 100,
              individualMutualCoefficient: this.individualMutualCoefficient / 100,
              individualMutualSalaryRate: this.individualMutualSalaryRate / 100,
              individualMutualEmployerRate: this.individualMutualEmployerRate / 100,
              individualMutualBase: this.individualMutualBase,
              familyMutualCoefficient: this.familyMutualCoefficient / 100,
              familyMutualSalaryRate: this.familyMutualSalaryRate / 100,
              familyMutualEmployerRate: this.familyMutualEmployerRate / 100,
              familyMutualBase: this.familyMutualBase,
              socialSecurityEmployerRate: this.socialSecurityEmployerRate / 100,
              csgCoefficient: this.csgCoefficient / 100,
              isSalaryBaseCoefficient: this.isSalaryBaseCoefficient,
              juniorCoefficientStatus: this.juniorCoefficientStatus,
              seniorCoefficientStatus: this.seniorCoefficientStatus,
              forfaitCoefficientStatus: this.forfaitCoefficientStatus,
              juniorFixedPriceStatus: this.juniorFixedPriceStatus * 100,
              seniorFixedPriceStatus: this.seniorFixedPriceStatus * 100,
              fixedPriceStatus: this.fixedPriceStatus * 100
            },
            workTime: {
              workingHoursOnFullTime: this.workingHoursOnFullTime
            },
            avantagesSociaux: {
              chomagePartiel: this.payrollPartialUnemploymentOption,
              indemnitesCongesPayes: this.indemnityPayedVacancy,
              forfaitGrandsDeplacements: {
                duree: this.payrollMajorMovementsDuration,
                value: this.payrollMajorMovementsOption
              },
              forfaitHotels: this.payrollHotelFixedPrices.map(hotelFixedPrice => {
                // eslint-disable-next-line no-unused-vars
                const { id, ...hotelFixedPriceWithoutId } = hotelFixedPrice
                return {
                  ...hotelFixedPriceWithoutId,
                  fixedPrice: hotelFixedPriceWithoutId.fixedPrice * 100
                }
              }),
              forfaitRepasMidi: this.payrollLunchFixedPrice * 100,
              forfaitRepasSoir: this.payrollDinnerFixedPrice * 100,
              pee: this.payrollPeeOption,
              perco: this.payrollPercoOption,
              teletravail: {
                value: this.payrollRemoteWorkOption,
                forfait: this.payrollRemoteWorkFixedPrice * 100,
              }
            },
            notifications: {
              dateOuverturePaie: this.payrollStartPayrollNotificationDate,
              email: this.payrollStartPayrollNotificationDateByEmail,
              kaizen: this.payrollStartPayrollNotificationDateOnEditor
            },
            salaryOperationMembers: extractSalaryOperationMembers({ salaryLines: this.salaryLines, constants: this.constants, settings: this.settings })
          }
        }

        await this.$store.dispatch('misc/updateSettings', payload)
        this.$toast.add({severity: 'success', summary: 'Succès', detail: 'Paramètres sauvegardés', life: 3000});
        await this.initializeFields()
      } catch (error) {
        console.log(error)
        this.$toast.add({severity: 'error', summary: 'Erreur', detail: 'Une erreur s\'est produite', life: 3000});
      }
    },
    addHotelFixedPrice () {
      this.payrollHotelFixedPrices.push({ id: this.lastHotelFixedPriceIdSet, label: '', fixedPrice: '' })
      ++this.lastHotelFixedPriceIdSet
    },
    deleteHotelFixedPrice (factoringAccount) {
      const index = this.payrollHotelFixedPrices.findIndex(advBankAccount => advBankAccount.id === factoringAccount.id)
      this.payrollHotelFixedPrices.splice(index, 1)
    },
    deleteSelectedHotelFixedPrice () {
      this.payrollSelectedHotelFixedPrices.forEach(salaryLine => {
        const index = this.payrollHotelFixedPrices.findIndex(salary => salary.id === salaryLine.id)
        if (index !== -1) {
          this.payrollHotelFixedPrices.splice(index, 1)
        }
      })
      this.payrollSelectedHotelFixedPrices = []
    },
    disableSavingButton () {
      this.disableSaving = true
    },
    enableSavingButton () {
      this.disableSaving = false
    },
    commercialFinancialReserveUpdated (payload) {
      this.commercialFinancialReserve.commercialFinancialReserveRateJunior = payload.commercialFinancialReserveRateJunior
      this.commercialFinancialReserve.commercialFinancialReserveRateSenior = payload.commercialFinancialReserveRateSenior
      this.commercialFinancialReserve.commercialFinancialReserveRateOthers = payload.commercialFinancialReserveRateOthers
      this.enableSavingButton()
    },
    addToLinesToShow (event, payload) {
      this.linesToShow.push(payload)
      this.showDefaultAllCheckedValue()
    },
    selectAllChanged () {
      if (this.selectAllLineChecked) {
        // this.linesToShow = []
        this.salaryLines.forEach(line => {
          if (line.useRowSelection) {
            line.selectedLine = true
            this.addToLinesToShow(line)
          }
        })
      } else {
        // this.linesToShow = []
        this.salaryLines.forEach(line => {
          line.selectedLine = false
        })
      }
    },
    addNewSalaryLine (payload) {
      const otherContribGroup = 'AUTRES CONTRIB. DUES PAR EMPL.'
      // eslint-disable-next-line no-unused-vars
      const { domain_id, ...modifiedPayload } = payload
      const lastCustomLineCount = this.salaryLines.filter(
          (salaryLine) => salaryLine.isDeletable && salaryLine.group === otherContribGroup
      ).length
      // this works only for the addition of "Other contribution" due par l'employeur
      this.salaryLines.push({
        ...modifiedPayload,
        id: this.getLastId() + 1,
        domain: `Autre contrib. dues par empl (${lastCustomLineCount + 1})`,
        specialBaseOptions: [ // this should be define somewhere else to be reusable
          this.constants.BASE_TYPES.BRUT_SALARY,
          this.constants.BASE_TYPES.PMSS,
          this.constants.BASE_TYPES.CAF,
          this.constants.BASE_TYPES.CAP,
          this.constants.BASE_TYPES.CA_AFTER_TGF,
          this.constants.BASE_TYPES.CA_AFTER_TGP,
          this.constants.BASE_TYPES.CUSTOM,
        ],
        editableDomain: true,
        isDeletable: true
      })
    },
    expandAllGroupRow () {
      const groupRowByGroup = []
      this.salaryLines.forEach((line) => {
        if (!groupRowByGroup.find((group) => group === line.group)) {
          groupRowByGroup.push(line.group)
        }
      })
      this.expandedRowGroups = groupRowByGroup
    },
    onRowEditSave (event) {
      let { newData, index } = event;
      if (newData.disabledBase) {
        // eslint-disable-next-line no-unused-vars
        const { base, ...data } = newData
        this.salaryLines[index] = data;
        return
      }
      if (typeof newData.base === 'object') {
        this.salaryLines[index] = newData;
        return
      }
      const newBase = JSON.parse(JSON.stringify(newData.base))
      if (!isNaN(parseFloat(newBase))) {
        newData.base = { label: newBase, type: 'custom'}
        this.salaryLines[index] = newData;
      } else {
        this.$toast.add({severity: 'error', summary: 'Erreur', detail: `La valeur "${newBase}" n'est pas valide`, life: 3000});
      }
    },
    async generateSalarySlip () {
      const salarySlipData = await this.$store.dispatch('simulation/generateSalarySlipPreview')
      this.pdfGenerator3 = new PdfGenerator({
        bulletin: salarySlipData.bulletin,
        name: 'PORTE' + ' ' + 'TEST',
        date: new Date(),
        settings: {
          companySettings: this.companySettings,
          settings: this.settings,
        },
        constants: this.constants
      }, 'bulletin')
      await this.pdfGenerator3.getPdf((dataUrl) => {
        this.salarySheetSrc = dataUrl
      })
    },
    getBaseOptions (data) {
      let baseOptions = []
      if (!data.specialBaseOptions) {
        baseOptions = JSON.parse(JSON.stringify(this.constants.data.baseOptions)).filter((baseOption) => {
          return this.constants.data.defaultBaseOptions.findIndex((defaultBaseOption) => defaultBaseOption === baseOption.type) !== -1;
        })
        return baseOptions
      }
      baseOptions = (JSON.parse(JSON.stringify(this.constants.data.baseOptions)).filter((baseOption) => {
        return data.specialBaseOptions.findIndex((specialBaseOption) => specialBaseOption === baseOption.type) !== -1;
      }))
      if (data.base.type === this.constants.BASE_TYPES.CUSTOM) {
        baseOptions.push({ type: this.constants.BASE_TYPES.CUSTOM, label: data.base.label })
      }
      return baseOptions
    },
    getLastId () {
      let lastId = 0
      // use the processedData to be sure to have the last added rows
      this.$refs.salaryLines.processedData.forEach((salaryLine) => {
        if (lastId < salaryLine.id) {
          lastId = salaryLine.id
        }
      })
      return lastId
    },
    showDefaultAllCheckedValue () {
      // show the checkbox to checked if every selectable line are selected
      this.selectAllLineChecked = this.salaryLines.every((salaryLine) =>
          !salaryLine.useRowSelection ? true : salaryLine.selectedLine
      )
    },
    askForDeleteSalaryLine (salary) {
      this.salaryLine = salary
      this.deleteSalaryLinesDialog = true
    },
    deleteSelectedSalaryLines () {
      const index = this.salaryLines.findIndex(salaryLine => salaryLine.id === this.salaryLine.id)
      this.salaryLines.splice(index, 1)
      this.deleteSalaryLinesDialog = false
    },
    // todo: should make a component with both input salary and employer rates to prevent duplication later on
    individualMutualSalaryRateBlur (event) {
      this.individualMutualSalaryRateMarginAdaptation = false
      if (event.value === null || event.value === '') {
        process.nextTick(() => {
          this.individualMutualSalaryRate = 0
        })
      }
    },
    individualMutualEmployerRateBlur (event) {
      this.individualMutualEmployerRateMarginAdaptation = false
      if (event.value === null || event.value === '') {
        process.nextTick(() => {
          this.individualMutualSalaryRate = 0
        })
      }
    },
    familyMutualSalaryRateBlur (event) {
      this.individualMutualSalaryRateMarginAdaptation = false
      if (event.value === null || event.value === '') {
        process.nextTick(() => {
          this.individualMutualSalaryRate = 0
        })
      }
    },
    familyMutualEmployerRateBlur (event) {
      this.individualMutualSalaryRateMarginAdaptation = false
      if (event.value === null || event.value === '') {
        process.nextTick(() => {
          this.individualMutualSalaryRate = 0
        })
      }
    },
  }
}
</script>

<style>
.operation-members-table td {
  padding-top: 0.3rem !important;
  padding-bottom: 0.3rem !important;
}
.operation-members-table tr.p-rowgroup-header {
  background-color: #eef2f7;
}
.operation-members-table td.row-editing-cell {
  padding: 0.3rem 0 !important;
}
.operation-members-table td.row-editing-cell .p-row-editor-save {
  background-color: green !important;
  color: white !important
}
.operation-members-table td.row-editing-cell .p-row-editor-cancel {
  background-color: red !important;
  color: white !important
}
.row-add-pay {
  padding: 0.3rem 0 !important;
}
</style>
