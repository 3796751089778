// eslint-disable-next-line
import {formatRateFromApi, formatRateToApi} from "@/utils/Misc";

export const getSalarySheetData = (settings, constants) => {
    // todo: construct thank the settings
    const salarySheetPrimes = [
        {
            id: 1,
            group: 'Primes',
            domain: 'Prime de précarité',
            domain_id: constants.salarySheetDomainIds.PRECARIOUSNESS_BONUS,
            base: null,
            salaryRate: formatRateFromApi(settings.pay.salaryOperationMembers?.GLOBAL?.PRECARIOUSNESS_BONUS?.salaryRate ?? 0),
            // salaryAmount: settings.pay.salaryOperationMembers?.GLOBAL.PRECARIOUSNESS_BONUS?.salaryAmount ?? 0,
            employerAmount: null,
            useRowSelection: false,
            selectedLine: true,
            addLineEnable: false,
            disabledBase: true,
        },
        {
            id: 2,
            group: 'Primes',
            domain: 'Prime de d\'apporteur d\'affaire',
            domain_id: constants.salarySheetDomainIds.BUSINESS_INTRODUCER_BONUS,
            base: settings.pay.salaryOperationMembers?.GLOBAL?.BUSINESS_INTRODUCER_BONUS?.base ?? {
                label: 'Salaire de base + complément de salaire',
                type: constants.BASE_TYPES.SALARY_BASE
            },
            specialBaseOptions: [
                constants.BASE_TYPES.SALARY_BASE,
                constants.BASE_TYPES.MINIMUM_CONVENTIONAL_SALARY,
            ],
            salaryRate: formatRateFromApi(settings.pay.salaryOperationMembers?.GLOBAL?.BUSINESS_INTRODUCER_BONUS?.salaryRate ?? 0),
            // salaryAmount: settings.pay.salaryOperationMembers?.GLOBAL.BUSINESS_INTRODUCER_BONUS?.salaryAmount ?? 0,
            employerAmount: null,
            useRowSelection: false,
            selectedLine: true,
            addLineEnable: false,
            disabledBase: false,
        }
        // {
        //     id: 3,
        //     group: 'Primes',
        //     domain: 'Prime d\'objectif',
        //     domain_id: constants.salarySheetDomainIds.BUSINESS_INTRODUCER_BONUS,
        //     base: 0,
        //     salaryRate: null,
        //     // salaryAmount: null,
        //     employerAmount: null,
        //     useRowSelection: false,
        //     selectedLine: false,
        //     addLineEnable: false,
        // }
    ]
    if (settings.pay.avantagesSociaux.indemnitesCongesPayes) {
        salarySheetPrimes.push(...[
            {
                id: 4,
                group: 'Primes',
                domain: 'Indemnité Congés Payés',
                domain_id: constants.salarySheetDomainIds.PAID_HOLIDAYS_BONUS,
                base: settings.pay.salaryOperationMembers?.GLOBAL?.PAID_HOLIDAYS_BONUS?.base ?? 0,
                salaryRate: formatRateFromApi(settings.pay.salaryOperationMembers?.GLOBAL?.PAID_HOLIDAYS_BONUS?.salaryRate ?? 0),
                // salaryAmount: settings.pay.salaryOperationMembers?.GLOBAL.PAID_HOLIDAYS_BONUS?.salaryAmount ?? 0,
                employerAmount: null,
                useRowSelection: false,
                selectedLine: true,
                addLineEnable: false,
                disabledBase: true
            },
            {
                id: 5,
                group: 'Primes',
                domain: 'Prime conventionnelle de vacances',
                domain_id: constants.salarySheetDomainIds.CONVENTIONAL_VACANCY_BONUS,
                base: settings.pay.salaryOperationMembers?.GLOBAL?.CONVENTIONAL_VACANCY_BONUS?.base ?? 0,
                salaryRate: formatRateFromApi(settings.pay.salaryOperationMembers?.GLOBAL?.CONVENTIONAL_VACANCY_BONUS?.salaryRate ?? 0),
                // salaryAmount: settings.pay.salaryOperationMembers?.GLOBAL.CONVENTIONAL_VACANCY_BONUS?.salaryAmount ?? 0,
                employerAmount: null,
                useRowSelection: false,
                selectedLine: true,
                addLineEnable: false,
                disabledBase: true
            }
        ])
    }
    const salarySheetHealth = [
        {
            id: 6,
            group: 'Santé',
            domain: 'Sécu.Soc-Mal.Mater.inval.Déc',
            domain_id: constants.salarySheetDomainIds.SOCIAL_SECURITY_ILLNESS_MATERNITY_DISABILITY_DEATH,
            base: settings.pay.salaryOperationMembers?.HEALTH?.SOCIAL_SECURITY_ILLNESS_MATERNITY_DISABILITY_DEATH?.base ?? {
                type: 'brutSalary',
                label: 'Salaire Brut'
            },
            salaryRate: null,
            // salaryAmount: null,
            employerAmount: formatRateFromApi(settings.pay.salaryOperationMembers?.HEALTH?.SOCIAL_SECURITY_ILLNESS_MATERNITY_DISABILITY_DEATH?.employerAmount ?? 0),
            useRowSelection: false,
            selectedLine: true,
            addLineEnable: false,
        },
        {
            id: 7,
            group: 'Santé',
            domain: 'Complémentaire Incap.Inval.Déc.Tranche A',
            domain_id: constants.salarySheetDomainIds.COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_A,
            base: null,
            salaryRate: formatRateFromApi(settings.pay.salaryOperationMembers?.HEALTH?.COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_A?.salaryRate),
            // salaryAmount: null,
            employerAmount: formatRateFromApi(settings.pay.salaryOperationMembers?.HEALTH?.COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_A?.employerAmount),
            useRowSelection: true,
            selectedLine: settings.pay.salaryOperationMembers?.HEALTH?.COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_A?.selectedLine,
            addLineEnable: false
        },
        {
            id: 8,
            group: 'Santé',
            domain: 'Complémentaire Incap.Inval.Déc.Tranche B',
            domain_id: constants.salarySheetDomainIds.COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_B,
            base: null,
            salaryRate: formatRateFromApi(settings.pay.salaryOperationMembers?.HEALTH?.COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_B?.salaryRate),
            // salaryAmount: null,
            employerAmount: formatRateFromApi(settings.pay.salaryOperationMembers?.HEALTH?.COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_B?.employerAmount),
            useRowSelection: true,
            selectedLine: settings.pay.salaryOperationMembers?.HEALTH?.COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_B?.selectedLine,
            addLineEnable: false,
            disabledBase: true
        },
        {
            id: 9,
            group: 'Santé',
            domain: 'Complémentaire Incap.Inval.Déc.Tranche C',
            domain_id: constants.salarySheetDomainIds.COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_C,
            base: null,
            salaryRate: formatRateFromApi(settings.pay.salaryOperationMembers?.HEALTH?.COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_C?.salaryRate),
            // salaryAmount: null,
            employerAmount: formatRateFromApi(settings.pay.salaryOperationMembers?.HEALTH?.COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_C?.employerAmount),
            useRowSelection: true,
            selectedLine: settings.pay.salaryOperationMembers?.HEALTH?.COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_C?.selectedLine,
            addLineEnable: false,
            disabledBase: true
        },
        {
            id: 10,
            group: 'Santé',
            domain: 'Mutuelle Obligatoire',
            domain_id: constants.salarySheetDomainIds.COMPULSORY_MUTUAL,
            base: settings.pay.salaryOperationMembers?.HEALTH?.COMPULSORY_MUTUAL?.base ?? {
                type: 'PMSS',
                label: 'PMSS',
            },
            salaryRate: formatRateFromApi(settings.pay.salaryOperationMembers?.HEALTH?.COMPULSORY_MUTUAL?.salaryRate ?? 0),
            // salaryAmount: settings.pay.salaryOperationMembers?.HEALTH.COMPULSORY_MUTUAL?.salaryAmount ?? 0,
            employerAmount: formatRateFromApi(settings.pay.salaryOperationMembers?.HEALTH?.COMPULSORY_MUTUAL?.employerAmount ?? 0),
            useRowSelection: true,
            selectedLine: settings.pay.salaryOperationMembers?.HEALTH?.COMPULSORY_MUTUAL?.selectedLine,
            addLineEnable: false,
        }
    ]
    const salarySheetATMP = [
        {
            id: 11,
            group: 'AT-MP',
            domain: 'Acc. du trav. -Mal.prof.',
            domain_id: constants.salarySheetDomainIds.WORK_ACCIDENTS_AND_OCCUPATIONAL_DISEASES,
            base: settings.pay.salaryOperationMembers?.OCCUPATIONAL_INJURIES_DISEASES_ACCOUNT?.WORK_ACCIDENTS_AND_OCCUPATIONAL_DISEASES?.base ?? {
                type: 'brutSalary',
                label: 'Salaire Brut'
            },
            salaryRate: null,
            // salaryAmount: null,
            employerAmount: formatRateFromApi(settings.pay.salaryOperationMembers?.OCCUPATIONAL_INJURIES_DISEASES_ACCOUNT?.WORK_ACCIDENTS_AND_OCCUPATIONAL_DISEASES?.employerAmount ?? 0),
            useRowSelection: true,
            selectedLine: settings.pay.salaryOperationMembers?.OCCUPATIONAL_INJURIES_DISEASES_ACCOUNT?.WORK_ACCIDENTS_AND_OCCUPATIONAL_DISEASES?.selectedLine,
            addLineEnable: false,
        }
    ]
    const salarySheetRetirement = [
        {
            id: 12,
            group: 'RETRAITE',
            domain: 'Sécu.Soc Plafonnée',
            base: settings.pay.salaryOperationMembers?.RETIREMENT.CAPPED_SOCIAL_SECURITY?.base ?? {
                type: 'PMSS',
                label: 'PMSS',
            },
            domain_id: constants.salarySheetDomainIds.CAPPED_SOCIAL_SECURITY,
            salaryRate: formatRateFromApi(settings.pay.salaryOperationMembers?.RETIREMENT?.CAPPED_SOCIAL_SECURITY?.salaryRate ?? 0),
            // salaryAmount: settings.pay.salaryOperationMembers?.RETIREMENT.CAPPED_SOCIAL_SECURITY?.salaryAmount ?? 0,
            employerAmount: formatRateFromApi(settings.pay.salaryOperationMembers?.RETIREMENT?.CAPPED_SOCIAL_SECURITY?.employerAmount ?? 0),
            useRowSelection: true,
            selectedLine: settings.pay.salaryOperationMembers?.RETIREMENT?.CAPPED_SOCIAL_SECURITY?.selectedLine,
            addLineEnable: false,
        },
        {
            id: 13,
            group: 'RETRAITE',
            domain: 'Sécu.Soc Déplafonnée',
            base: settings.pay.salaryOperationMembers?.RETIREMENT?.UNCAPPED_SOCIAL_SECURITY?.base ?? {
                type: 'brutSalary',
                label: 'Salaire Brut'
            },
            domain_id: constants.salarySheetDomainIds.UNCAPPED_SOCIAL_SECURITY,
            salaryRate: formatRateFromApi(settings.pay.salaryOperationMembers?.RETIREMENT?.UNCAPPED_SOCIAL_SECURITY?.salaryRate ?? 0),
            // salaryAmount: settings.pay.salaryOperationMembers?.RETIREMENT.UNCAPPED_SOCIAL_SECURITY?.salaryAmount ?? 0,
            employerAmount: formatRateFromApi(settings.pay.salaryOperationMembers?.RETIREMENT?.UNCAPPED_SOCIAL_SECURITY?.employerAmount ?? 0),
            useRowSelection: true,
            selectedLine: settings.pay.salaryOperationMembers?.RETIREMENT?.UNCAPPED_SOCIAL_SECURITY?.selectedLine,
            addLineEnable: false,
        },
        {
            id: 14,
            group: 'RETRAITE',
            domain: 'Complémentaire Tranche A',
            base: null,
            domain_id: constants.salarySheetDomainIds.COMPLEMENTARY_A,
            salaryRate: formatRateFromApi(settings.pay.salaryOperationMembers?.RETIREMENT?.COMPLEMENTARY_A?.salaryRate ?? 0),
            // salaryAmount: settings.pay.salaryOperationMembers?.RETIREMENT.COMPLEMENTARY_A?.salaryAmount ?? 0,
            employerAmount: formatRateFromApi(settings.pay.salaryOperationMembers?.RETIREMENT?.COMPLEMENTARY_A?.employerAmount ?? 0),
            useRowSelection: true,
            selectedLine: settings.pay.salaryOperationMembers?.RETIREMENT?.COMPLEMENTARY_A?.selectedLine,
            addLineEnable: false,
        },
        {
            id: 15,
            group: 'RETRAITE',
            domain: 'Complémentaire Tranche B',
            base: null,
            domain_id: constants.salarySheetDomainIds.COMPLEMENTARY_B,
            salaryRate: formatRateFromApi(settings.pay.salaryOperationMembers?.RETIREMENT?.COMPLEMENTARY_B?.salaryRate ?? 0),
            // salaryAmount: settings.pay.salaryOperationMembers?.RETIREMENT.COMPLEMENTARY_B?.salaryAmount ?? 0,
            employerAmount: formatRateFromApi(settings.pay.salaryOperationMembers?.RETIREMENT?.COMPLEMENTARY_B?.employerAmount ?? 0),
            useRowSelection: true,
            selectedLine: settings.pay.salaryOperationMembers?.RETIREMENT?.COMPLEMENTARY_B?.selectedLine,
            addLineEnable: false,
            disabledBase: true
        }
    ]
    const salarySheetSocialSecurityFamily = [
        {
            id: 16,
            group: 'FAMILLE-SECURITE-SOCIALE',
            domain: 'AF',
            base: settings.pay.salaryOperationMembers?.SOCIAL_SECURITY_FAMILY?.AF?.base ?? {
                type: 'brutSalary',
                label: 'Salaire Brut'
            },
            domain_id: constants.salarySheetDomainIds.AF,
            salaryRate: null,
            // salaryAmount: null,
            employerAmount: formatRateFromApi(settings.pay.salaryOperationMembers?.SOCIAL_SECURITY_FAMILY?.AF?.employerAmount ?? 0),
            useRowSelection: true,
            selectedLine: settings.pay.salaryOperationMembers?.SOCIAL_SECURITY_FAMILY?.AF?.selectedLine,
            addLineEnable: false,
        }
    ]
    const salarySheetUnemploymentInsurance = [
        {
            id: 17,
            group: 'ASSURANCE CHOMAGE',
            domain: 'Chômage',
            base: settings.pay.salaryOperationMembers?.UNEMPLOYMENT_INSURANCE?.UNEMPLOYMENT?.base ?? {
                type: 'brutSalary',
                label: 'Salaire Brut'
            },
            domain_id: constants.salarySheetDomainIds.UNEMPLOYMENT,
            salaryRate: null,
            // salaryAmount: null,
            employerAmount: formatRateFromApi(settings.pay.salaryOperationMembers?.UNEMPLOYMENT_INSURANCE?.UNEMPLOYMENT?.employerAmount ?? 0),
            useRowSelection: true,
            selectedLine: settings.pay.salaryOperationMembers?.UNEMPLOYMENT_INSURANCE?.UNEMPLOYMENT?.selectedLine,
            addLineEnable: false,
        },
        {
            id: 18,
            group: 'ASSURANCE CHOMAGE',
            domain: 'AGS',
            base: settings.pay.salaryOperationMembers?.UNEMPLOYMENT_INSURANCE?.EMPLOYEE_ASSOCIATION_FOR_GUARANTEE?.base ?? {
                type: 'brutSalary',
                label: 'Salaire Brut'
            },
            domain_id: constants.salarySheetDomainIds.EMPLOYEE_ASSOCIATION_FOR_GUARANTEE,
            salaryRate: null,
            // salaryAmount: null,
            employerAmount: formatRateFromApi(settings.pay.salaryOperationMembers?.UNEMPLOYMENT_INSURANCE?.EMPLOYEE_ASSOCIATION_FOR_GUARANTEE?.employerAmount ?? 0),
            useRowSelection: true,
            selectedLine: settings.pay.salaryOperationMembers?.UNEMPLOYMENT_INSURANCE?.EMPLOYEE_ASSOCIATION_FOR_GUARANTEE?.selectedLine,
            addLineEnable: false,
        },
        {
            id: 19,
            group: 'ASSURANCE CHOMAGE',
            domain: 'APEC',
            base: settings.pay.salaryOperationMembers?.UNEMPLOYMENT_INSURANCE.EXECUTIVE_ASSOCIATION_EMPLOYMENT?.base ?? {
                type: 'brutSalary',
                label: 'Salaire Brut'
            },
            domain_id: constants.salarySheetDomainIds.EXECUTIVE_ASSOCIATION_EMPLOYMENT,
            salaryRate: formatRateFromApi(settings.pay.salaryOperationMembers?.UNEMPLOYMENT_INSURANCE?.EXECUTIVE_ASSOCIATION_EMPLOYMENT?.salaryRate ?? 0),
            // salaryAmount: null,
            employerAmount: formatRateFromApi(settings.pay.salaryOperationMembers?.UNEMPLOYMENT_INSURANCE?.EXECUTIVE_ASSOCIATION_EMPLOYMENT?.employerAmount ?? 0),
            useRowSelection: true,
            selectedLine: settings.pay.salaryOperationMembers?.UNEMPLOYMENT_INSURANCE?.EXECUTIVE_ASSOCIATION_EMPLOYMENT?.selectedLine,
            addLineEnable: false,
        }
    ]
    const salarySheetCSG = [
        {
            id: 20,
            group: 'CSG non imposable/déductible à l\'IR',
            domain: 'CSG non imposable/déductible à l\'IR',
            base: null,
            domain_id: constants.salarySheetDomainIds.GENERAL_SOCIAL_CONTRIBUTION,
            salaryRate: formatRateFromApi(settings.pay.salaryOperationMembers?.GENERAL_SOCIAL_CONTRIBUTION?.salaryRate ?? 0),
            // salaryAmount: null,
            employerAmount: null,
            useRowSelection: true,
            selectedLine: settings.pay.salaryOperationMembers?.GENERAL_SOCIAL_CONTRIBUTION?.selectedLine,
            addLineEnable: false,
            disabledBase: true
        }
    ]
    const salarySheetCSG_CRDS = [
        {
            id: 21,
            group: 'CSG/CRDS imposable à l\'IR',
            domain: 'CSG/CRDS imposable à l\'IR',
            base: null,
            domain_id: constants.salarySheetDomainIds.CSG_CRDS,
            salaryRate: formatRateFromApi(settings.pay.salaryOperationMembers?.CSG_CRDS?.salaryRate ?? 0),
            // salaryAmount: null,
            employerAmount: null,
            useRowSelection: true,
            selectedLine: settings.pay.salaryOperationMembers?.CSG_CRDS?.selectedLine,
            addLineEnable: false,
            disabledBase: true
        }
    ]
    const salarySheetADESATT = [
        {
            id: 22,
            group: 'ADESATT',
            domain: 'ADESATT',
            base: settings.pay.salaryOperationMembers?.ADESATT?.base ?? {
                type: 'brutSalary',
                label: 'Salaire Brut'
            },
            domain_id: constants.salarySheetDomainIds.ADESATT,
            salaryRate: null,
            // salaryAmount: null,
            employerAmount: formatRateFromApi(settings.pay.salaryOperationMembers?.ADESATT?.employerAmount ?? 0),
            useRowSelection: true,
            selectedLine: settings.pay.salaryOperationMembers?.ADESATT?.selectedLine,
            addLineEnable: false,
        }
    ]
    const salarySheetStatuaryFees = [
        {
            id: 23,
            group: 'COTISATIONS STATUAIRES OU CC',
            domain: 'COTISATIONS STATUAIRES OU CC',
            base: settings.pay.salaryOperationMembers?.STATUARY_FEE?.base ?? {
                type: 'PMSS',
                label: 'PMSS',
            },
            domain_id: constants.salarySheetDomainIds.STATUARY_FEE,
            salaryRate: null,
            // salaryAmount: null,
            employerAmount: formatRateFromApi(settings.pay.salaryOperationMembers?.STATUARY_FEE?.employerAmount ?? 0),
            useRowSelection: true,
            selectedLine: settings.pay.salaryOperationMembers?.STATUARY_FEE?.selectedLine,
            addLineEnable: false,
        }
    ]

    // This order is important to maintain the work
    const salarySheetData = [
        ...salarySheetPrimes,
        ...salarySheetHealth,
        ...salarySheetATMP,
        ...salarySheetRetirement,
        ...salarySheetSocialSecurityFamily,
        ...salarySheetUnemploymentInsurance,
        ...salarySheetCSG,
        ...salarySheetCSG_CRDS,
        ...salarySheetADESATT,
        ...salarySheetStatuaryFees
    ]

    // default Contribution due par l'employeur
    // salarySheetData.push({
    //     id: 24,
    //     group: 'AUTRES CONTRIB. DUES PAR EMPL.',
    //     domain: 'Autre contrib. dues par empl.',
    //     base: settings.pay.salaryOperationMembers?.DEFAULT_OTHER_DUE_EMPLOYER_CONTRIBUTION.OTHER_DUE_EMPLOYER_CONTRIBUTION.base,
    //     domain_id: constants.salarySheetDomainIds.OTHER_DUE_EMPLOYER_CONTRIBUTION,
    //     salaryRate: null,
    //     // salaryAmount: null,
    //     employerAmount: formatRateFromApi(settings.pay.salaryOperationMembers?.DEFAULT_OTHER_DUE_EMPLOYER_CONTRIBUTION.OTHER_DUE_EMPLOYER_CONTRIBUTION.employerAmount),
    //     useRowSelection: true,
    //     selectedLine: settings.pay.salaryOperationMembers?.DEFAULT_OTHER_DUE_EMPLOYER_CONTRIBUTION.OTHER_DUE_EMPLOYER_CONTRIBUTION.selectedLine,
    //     addLineEnable: true,
    //     editableDomain: false
    // })
    salarySheetData.push({
        id: 24,
        group: 'AUTRES CONTRIB. DUES PAR EMPL.',
        domain: 'Contribution solidarité Autonomie',
        base: settings.pay.salaryOperationMembers?.DEFAULT_OTHER_DUE_EMPLOYER_CONTRIBUTION?.AUTONOMY_SOLIDARITY_CONTRIBUTION?.base,
        domain_id: constants.salarySheetDomainIds.AUTONOMY_SOLIDARITY_CONTRIBUTION,
        salaryRate: null,
        // salaryAmount: null,
        employerAmount: formatRateFromApi(settings.pay.salaryOperationMembers?.DEFAULT_OTHER_DUE_EMPLOYER_CONTRIBUTION?.AUTONOMY_SOLIDARITY_CONTRIBUTION?.employerAmount),
        useRowSelection: true,
        selectedLine: settings.pay.salaryOperationMembers?.DEFAULT_OTHER_DUE_EMPLOYER_CONTRIBUTION?.AUTONOMY_SOLIDARITY_CONTRIBUTION?.selectedLine,
        addLineEnable: true,
        editableDomain: false
    })
    salarySheetData.push({
        id: 25,
        group: 'AUTRES CONTRIB. DUES PAR EMPL.',
        domain: 'FNAL déplafonné',
        base: settings.pay.salaryOperationMembers?.DEFAULT_OTHER_DUE_EMPLOYER_CONTRIBUTION?.UNCAPPED_FNAL?.base,
        domain_id: constants.salarySheetDomainIds.UNCAPPED_FNAL,
        salaryRate: null,
        // salaryAmount: null,
        employerAmount: formatRateFromApi(settings.pay.salaryOperationMembers?.DEFAULT_OTHER_DUE_EMPLOYER_CONTRIBUTION?.UNCAPPED_FNAL?.employerAmount),
        useRowSelection: true,
        selectedLine: settings.pay.salaryOperationMembers?.DEFAULT_OTHER_DUE_EMPLOYER_CONTRIBUTION?.UNCAPPED_FNAL?.selectedLine,
        addLineEnable: true,
        editableDomain: false
    })
    salarySheetData.push({
        id: 26,
        group: 'AUTRES CONTRIB. DUES PAR EMPL.',
        domain: 'Versement mobilité',
        base: settings.pay.salaryOperationMembers?.DEFAULT_OTHER_DUE_EMPLOYER_CONTRIBUTION?.MOBILITY_TRANSFER?.base,
        domain_id: constants.salarySheetDomainIds.MOBILITY_TRANSFER,
        salaryRate: null,
        // salaryAmount: null,
        employerAmount: formatRateFromApi(settings.pay.salaryOperationMembers?.DEFAULT_OTHER_DUE_EMPLOYER_CONTRIBUTION?.MOBILITY_TRANSFER?.employerAmount),
        useRowSelection: true,
        selectedLine: settings.pay.salaryOperationMembers?.DEFAULT_OTHER_DUE_EMPLOYER_CONTRIBUTION?.MOBILITY_TRANSFER?.selectedLine,
        addLineEnable: true,
        editableDomain: false
    })
    salarySheetData.push({
        id: 27,
        group: 'AUTRES CONTRIB. DUES PAR EMPL.',
        domain: 'Contribution au dialogue social',
        base: settings.pay.salaryOperationMembers?.DEFAULT_OTHER_DUE_EMPLOYER_CONTRIBUTION?.SOCIAL_DIALOG_CONTRIBUTION?.base,
        domain_id: constants.salarySheetDomainIds.SOCIAL_DIALOG_CONTRIBUTION,
        salaryRate: null,
        // salaryAmount: null,
        employerAmount: formatRateFromApi(settings.pay.salaryOperationMembers?.DEFAULT_OTHER_DUE_EMPLOYER_CONTRIBUTION?.SOCIAL_DIALOG_CONTRIBUTION?.employerAmount),
        useRowSelection: true,
        selectedLine: settings.pay.salaryOperationMembers?.DEFAULT_OTHER_DUE_EMPLOYER_CONTRIBUTION?.SOCIAL_DIALOG_CONTRIBUTION?.selectedLine,
        addLineEnable: true,
        editableDomain: false
    })
    salarySheetData.push({
        id: 28,
        group: 'AUTRES CONTRIB. DUES PAR EMPL.',
        domain: 'Contribution formation professionnelle',
        base: settings.pay.salaryOperationMembers?.DEFAULT_OTHER_DUE_EMPLOYER_CONTRIBUTION?.PRO_FORMATION_CONTRIBUTION?.base,
        domain_id: constants.salarySheetDomainIds.PRO_FORMATION_CONTRIBUTION,
        salaryRate: null,
        // salaryAmount: null,
        employerAmount: formatRateFromApi(settings.pay.salaryOperationMembers?.DEFAULT_OTHER_DUE_EMPLOYER_CONTRIBUTION?.PRO_FORMATION_CONTRIBUTION?.employerAmount),
        useRowSelection: true,
        selectedLine: settings.pay.salaryOperationMembers?.DEFAULT_OTHER_DUE_EMPLOYER_CONTRIBUTION?.PRO_FORMATION_CONTRIBUTION?.selectedLine,
        addLineEnable: true,
        editableDomain: false
    })
    salarySheetData.push({
        id: 29,
        group: 'AUTRES CONTRIB. DUES PAR EMPL.',
        domain: 'Participation à l\'effort de construction',
        base: settings.pay.salaryOperationMembers?.DEFAULT_OTHER_DUE_EMPLOYER_CONTRIBUTION?.CONSTRUCTION_EFFORT_PARTICIPATION?.base,
        domain_id: constants.salarySheetDomainIds.CONSTRUCTION_EFFORT_PARTICIPATION,
        salaryRate: null,
        // salaryAmount: null,
        employerAmount: formatRateFromApi(settings.pay.salaryOperationMembers?.DEFAULT_OTHER_DUE_EMPLOYER_CONTRIBUTION?.CONSTRUCTION_EFFORT_PARTICIPATION?.employerAmount),
        useRowSelection: true,
        selectedLine: settings.pay.salaryOperationMembers?.DEFAULT_OTHER_DUE_EMPLOYER_CONTRIBUTION?.CONSTRUCTION_EFFORT_PARTICIPATION?.selectedLine,
        addLineEnable: true,
        editableDomain: false
    })
    salarySheetData.push({
        id: 30,
        group: 'AUTRES CONTRIB. DUES PAR EMPL.',
        domain: 'Taxe à l\'apprentissage',
        base: settings.pay.salaryOperationMembers?.DEFAULT_OTHER_DUE_EMPLOYER_CONTRIBUTION?.LEARNING_TAXES?.base,
        domain_id: constants.salarySheetDomainIds.LEARNING_TAXES,
        salaryRate: null,
        // salaryAmount: null,
        employerAmount: formatRateFromApi(settings.pay.salaryOperationMembers?.DEFAULT_OTHER_DUE_EMPLOYER_CONTRIBUTION?.LEARNING_TAXES?.employerAmount),
        useRowSelection: true,
        selectedLine: settings.pay.salaryOperationMembers?.DEFAULT_OTHER_DUE_EMPLOYER_CONTRIBUTION?.LEARNING_TAXES?.selectedLine,
        addLineEnable: true,
        editableDomain: false
    })
    salarySheetData.push({
        id: 31,
        group: 'AUTRES CONTRIB. DUES PAR EMPL.',
        domain: 'Autre contrib. dues par empl. \n (Forfait social prévoyance)',
        base: null,
        salaryRate: null,
        domain_id: constants.salarySheetDomainIds.OTHER_DUE_EMPLOYER_CONTRIBUTION_PROVISIONING_SOCIAL_FEE,
        // salaryAmount: null,
        employerAmount: formatRateFromApi(settings.pay.salaryOperationMembers?.DEFAULT_OTHER_DUE_EMPLOYER_CONTRIBUTION?.OTHER_DUE_EMPLOYER_CONTRIBUTION_PROVISIONING_SOCIAL_FEE?.employerAmount),
        useRowSelection: true,
        selectedLine: settings.pay.salaryOperationMembers?.DEFAULT_OTHER_DUE_EMPLOYER_CONTRIBUTION?.OTHER_DUE_EMPLOYER_CONTRIBUTION_PROVISIONING_SOCIAL_FEE?.selectedLine,
        addLineEnable: true,
        editableDomain: false,
        disabledBase: true
    })
    let lastId = salarySheetData[salarySheetData.length - 1].id
    settings.pay.salaryOperationMembers?.OTHER_DUE_EMPLOYER_CONTRIBUTION.forEach((otherDueEmployerContribution) => {
        salarySheetData.push({
            id: ++lastId,
            group: 'AUTRES CONTRIB. DUES PAR EMPL.',
            domain: otherDueEmployerContribution.domain,
            base: otherDueEmployerContribution?.base,
            salaryRate: null,
            // salaryAmount: null,
            employerAmount: formatRateFromApi(otherDueEmployerContribution?.employerAmount),
            useRowSelection: true,
            selectedLine: otherDueEmployerContribution.selectedLine,
            addLineEnable: true,
            specialBaseOptions: [
                constants.BASE_TYPES.BRUT_SALARY,
                constants.BASE_TYPES.PMSS,
                constants.BASE_TYPES.CAF,
                constants.BASE_TYPES.CAP,
                constants.BASE_TYPES.CA_AFTER_TGF,
                constants.BASE_TYPES.CA_AFTER_TGP,
                constants.BASE_TYPES.CUSTOM,
            ],
            editableDomain: true,
            isDeletable: true
        })
    })
    return salarySheetData
}

// eslint-disable-next-line
export const extractSalaryOperationMembers = (data) => {
    const findIndexMember = (domainId) => {
        return data.salaryLines.findIndex((salaryLine) => salaryLine.domain_id === domainId)
    }

    // todo: could be really more optimized && automatic
    // for (let i = 0; i < data.salaryLines.length; i++) {
    //     if (data.salaryLines[i].domain_id === data.constants.salarySheetDomainIds.BUSINESS_INTRODUCER_BONUS)
    // }

    const BUSINESS_INTRODUCER_BONUS_INDEX = findIndexMember(data.constants.salarySheetDomainIds.BUSINESS_INTRODUCER_BONUS)
    const PRECARIOUSNESS_BONUS_INDEX = findIndexMember(data.constants.salarySheetDomainIds.PRECARIOUSNESS_BONUS)
    const PAID_HOLIDAYS_BONUS_INDEX = findIndexMember(data.constants.salarySheetDomainIds.PAID_HOLIDAYS_BONUS)
    // const OBJECTIVE_BONUS_INDEX = findIndexMember(data.constants.salarySheetDomainIds.OBJECTIVE_BONUS)
    const CONVENTIONAL_VACANCY_BONUS_INDEX = findIndexMember(data.constants.salarySheetDomainIds.CONVENTIONAL_VACANCY_BONUS)

    const SOCIAL_SECURITY_ILLNESS_MATERNITY_DISABILITY_DEATH_INDEX = findIndexMember(data.constants.salarySheetDomainIds.SOCIAL_SECURITY_ILLNESS_MATERNITY_DISABILITY_DEATH)
    const COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_A_INDEX = findIndexMember(data.constants.salarySheetDomainIds.COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_A)
    const COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_B_INDEX = findIndexMember(data.constants.salarySheetDomainIds.COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_B)
    const COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_C_INDEX = findIndexMember(data.constants.salarySheetDomainIds.COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_C)
    const COMPULSORY_MUTUAL_INDEX = findIndexMember(data.constants.salarySheetDomainIds.COMPULSORY_MUTUAL)

    const WORK_ACCIDENTS_AND_OCCUPATIONAL_DISEASES_INDEX = findIndexMember(data.constants.salarySheetDomainIds.WORK_ACCIDENTS_AND_OCCUPATIONAL_DISEASES)

    const CAPPED_SOCIAL_SECURITY_INDEX = findIndexMember(data.constants.salarySheetDomainIds.CAPPED_SOCIAL_SECURITY)
    const UNCAPPED_SOCIAL_SECURITY_INDEX = findIndexMember(data.constants.salarySheetDomainIds.UNCAPPED_SOCIAL_SECURITY)
    const COMPLEMENTARY_A_INDEX = findIndexMember(data.constants.salarySheetDomainIds.COMPLEMENTARY_A)
    const COMPLEMENTARY_B_INDEX = findIndexMember(data.constants.salarySheetDomainIds.COMPLEMENTARY_B)

    const AF_INDEX = findIndexMember(data.constants.salarySheetDomainIds.AF)

    const UNEMPLOYMENT_INDEX = findIndexMember(data.constants.salarySheetDomainIds.UNEMPLOYMENT)
    const EMPLOYEE_ASSOCIATION_FOR_GUARANTEE_INDEX = findIndexMember(data.constants.salarySheetDomainIds.EMPLOYEE_ASSOCIATION_FOR_GUARANTEE)
    const EXECUTIVE_ASSOCIATION_EMPLOYMENT_INDEX = findIndexMember(data.constants.salarySheetDomainIds.EXECUTIVE_ASSOCIATION_EMPLOYMENT)

    const GENERAL_SOCIAL_CONTRIBUTION_INDEX = findIndexMember(data.constants.salarySheetDomainIds.GENERAL_SOCIAL_CONTRIBUTION)
    const CSG_CRDS_INDEX = findIndexMember(data.constants.salarySheetDomainIds.CSG_CRDS)
    const ADESATT_INDEX = findIndexMember(data.constants.salarySheetDomainIds.ADESATT)
    const STATUARY_FEE_INDEX = findIndexMember(data.constants.salarySheetDomainIds.STATUARY_FEE)

    // const OTHER_DUE_EMPLOYER_CONTRIBUTION = findIndexMember(data.constants.salarySheetDomainIds.OTHER_DUE_EMPLOYER_CONTRIBUTION)
    const AUTONOMY_SOLIDARITY_CONTRIBUTION_INDEX = findIndexMember(data.constants.salarySheetDomainIds.AUTONOMY_SOLIDARITY_CONTRIBUTION)
    const UNCAPPED_FNAL_INDEX = findIndexMember(data.constants.salarySheetDomainIds.UNCAPPED_FNAL)
    const MOBILITY_TRANSFER_INDEX = findIndexMember(data.constants.salarySheetDomainIds.MOBILITY_TRANSFER)
    const SOCIAL_DIALOG_CONTRIBUTION_INDEX = findIndexMember(data.constants.salarySheetDomainIds.SOCIAL_DIALOG_CONTRIBUTION)
    const PRO_FORMATION_CONTRIBUTION_INDEX = findIndexMember(data.constants.salarySheetDomainIds.PRO_FORMATION_CONTRIBUTION)
    const CONSTRUCTION_EFFORT_PARTICIPATION_INDEX = findIndexMember(data.constants.salarySheetDomainIds.CONSTRUCTION_EFFORT_PARTICIPATION)
    const LEARNING_TAXES_INDEX = findIndexMember(data.constants.salarySheetDomainIds.LEARNING_TAXES)
    const OTHER_DUE_EMPLOYER_CONTRIBUTION_PROVISIONING_SOCIAL_FEE = findIndexMember(data.constants.salarySheetDomainIds.OTHER_DUE_EMPLOYER_CONTRIBUTION_PROVISIONING_SOCIAL_FEE)

    // console.log('OTHER_DUE_EMPLOYER_CONTRIBUTION', OTHER_DUE_EMPLOYER_CONTRIBUTION)
    // console.log('OTHER_DUE_EMPLOYER_CONTRIBUTION_PROVISIONING_SOCIAL_FEE', OTHER_DUE_EMPLOYER_CONTRIBUTION_PROVISIONING_SOCIAL_FEE)
    // todo: go through the array and get construct the operationMember object to register
    return {
        GLOBAL: {
            BUSINESS_INTRODUCER_BONUS: {
                base: data.salaryLines[BUSINESS_INTRODUCER_BONUS_INDEX].base,
                salaryRate: formatRateToApi(data.salaryLines[BUSINESS_INTRODUCER_BONUS_INDEX].salaryRate),
                // salaryAmount: data.salaryLines[1].salaryAmount,
                employerAmount: null, // force to null
            },
            PRECARIOUSNESS_BONUS: {
                base: data.salaryLines[PRECARIOUSNESS_BONUS_INDEX].base,
                salaryRate: formatRateToApi(data.salaryLines[PRECARIOUSNESS_BONUS_INDEX].salaryRate),
                // salaryAmount: data.salaryLines[0].salaryAmount,
                employerAmount: null, // force to null
            },
            PAID_HOLIDAYS_BONUS: {
                base: null, // PAID_HOLIDAYS_BONUS_INDEX === -1 ? data.settings.salaryOperationMembers.GLOBAL.PAID_HOLIDAYS_BONUS.base : data.salaryLines[PAID_HOLIDAYS_BONUS_INDEX].base
                salaryRate: formatRateToApi(PAID_HOLIDAYS_BONUS_INDEX === -1 ? (data.settings.salaryOperationMembers.GLOBAL.PAID_HOLIDAYS_BONUS.salaryRate * 100) : data.salaryLines[PAID_HOLIDAYS_BONUS_INDEX].salaryRate),
                // salaryAmount: data.salaryLines[3].salaryAmount,
                employerAmount: null, // force to null
            },
            // OBJECTIVE_BONUS: { // todo: update with the previous value if there should not be Objective Bonus
            //     base: data.salaryLines[OBJECTIVE_BONUS_INDEX].base,
            //     salaryRate: formatRateToApi(data.salaryLines[OBJECTIVE_BONUS_INDEX].salaryRate),
            //     // salaryAmount: data.salaryLines[2].salaryAmount,
            //     employerAmount: null, // force to null
            // },
            CONVENTIONAL_VACANCY_BONUS: {
                base: null, // PAID_HOLIDAYS_BONUS_INDEX === -1 ? data.settings.salaryOperationMembers.GLOBAL.CONVENTIONAL_VACANCY_BONUS.base : data.salaryLines[CONVENTIONAL_VACANCY_BONUS_INDEX].base
                salaryRate: formatRateToApi(PAID_HOLIDAYS_BONUS_INDEX === -1 ? (data.settings.salaryOperationMembers.GLOBAL.CONVENTIONAL_VACANCY_BONUS.salaryRate * 100) : data.salaryLines[CONVENTIONAL_VACANCY_BONUS_INDEX].salaryRate),
                // salaryAmount: data.salaryLines[4].salaryAmount,
                employerAmount: null, // force to null
            }
        },
        HEALTH: {
            SOCIAL_SECURITY_ILLNESS_MATERNITY_DISABILITY_DEATH: {
                base: data.salaryLines[SOCIAL_SECURITY_ILLNESS_MATERNITY_DISABILITY_DEATH_INDEX].base,
                salaryRate: formatRateToApi(data.salaryLines[SOCIAL_SECURITY_ILLNESS_MATERNITY_DISABILITY_DEATH_INDEX].salaryRate),
                // salaryAmount: data.salaryLines[5].salaryAmount,
                employerAmount: formatRateToApi(data.salaryLines[SOCIAL_SECURITY_ILLNESS_MATERNITY_DISABILITY_DEATH_INDEX].employerAmount), // force to null
            },
            COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_A: {
                base: data.salaryLines[COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_A_INDEX].base,
                salaryRate: formatRateToApi(data.salaryLines[COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_A_INDEX].salaryRate),
                // salaryAmount: data.salaryLines[COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_A_INDEX].salaryAmount,
                employerAmount: formatRateToApi(data.salaryLines[COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_A_INDEX].employerAmount), // force to null
                selectedLine: data.salaryLines[COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_A_INDEX].selectedLine
            },
            COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_B: {
                base: data.salaryLines[COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_B_INDEX].base,
                salaryRate: formatRateToApi(data.salaryLines[COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_B_INDEX].salaryRate),
                // salaryAmount: data.salaryLines[COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_B_INDEX].salaryAmount,
                employerAmount: formatRateToApi(data.salaryLines[COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_B_INDEX].employerAmount), // force to null
                selectedLine: data.salaryLines[COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_B_INDEX].selectedLine
            },
            COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_C: {
                base: data.salaryLines[COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_C_INDEX].base,
                salaryRate: formatRateToApi(data.salaryLines[COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_C_INDEX].salaryRate),
                // salaryAmount: data.salaryLines[COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_C_INDEX].salaryAmount,
                employerAmount: formatRateToApi(data.salaryLines[COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_C_INDEX].employerAmount), // force to null
                selectedLine: data.salaryLines[COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_C_INDEX].selectedLine
            },
            COMPULSORY_MUTUAL: {
                base: data.salaryLines[COMPULSORY_MUTUAL_INDEX].base,
                salaryRate: formatRateToApi(data.salaryLines[COMPULSORY_MUTUAL_INDEX].salaryRate),
                // salaryAmount: data.salaryLines[COMPULSORY_MUTUAL_INDEX].salaryAmount,
                employerAmount: formatRateToApi(data.salaryLines[COMPULSORY_MUTUAL_INDEX].employerAmount), // force to null
                selectedLine: data.salaryLines[COMPULSORY_MUTUAL_INDEX].selectedLine
            }
        },
        OCCUPATIONAL_INJURIES_DISEASES_ACCOUNT: {
            WORK_ACCIDENTS_AND_OCCUPATIONAL_DISEASES: {
                base: data.salaryLines[WORK_ACCIDENTS_AND_OCCUPATIONAL_DISEASES_INDEX].base,
                salaryRate: formatRateToApi(data.salaryLines[WORK_ACCIDENTS_AND_OCCUPATIONAL_DISEASES_INDEX].salaryRate),
                // salaryAmount: data.salaryLines[WORK_ACCIDENTS_AND_OCCUPATIONAL_DISEASES_INDEX].salaryAmount,
                employerAmount: formatRateToApi(data.salaryLines[WORK_ACCIDENTS_AND_OCCUPATIONAL_DISEASES_INDEX].employerAmount), // force to null
                selectedLine: data.salaryLines[WORK_ACCIDENTS_AND_OCCUPATIONAL_DISEASES_INDEX].selectedLine
            }
        },
        RETIREMENT: {
            CAPPED_SOCIAL_SECURITY: {
                base: data.salaryLines[CAPPED_SOCIAL_SECURITY_INDEX].base,
                salaryRate: formatRateToApi(data.salaryLines[CAPPED_SOCIAL_SECURITY_INDEX].salaryRate),
                // salaryAmount: data.salaryLines[CAPPED_SOCIAL_SECURITY_INDEX].salaryAmount,
                employerAmount: formatRateToApi(data.salaryLines[CAPPED_SOCIAL_SECURITY_INDEX].employerAmount), // force to null
                selectedLine: data.salaryLines[CAPPED_SOCIAL_SECURITY_INDEX].selectedLine
            },
            UNCAPPED_SOCIAL_SECURITY: {
                base: data.salaryLines[UNCAPPED_SOCIAL_SECURITY_INDEX].base,
                salaryRate: formatRateToApi(data.salaryLines[UNCAPPED_SOCIAL_SECURITY_INDEX].salaryRate),
                // salaryAmount: data.salaryLines[UNCAPPED_SOCIAL_SECURITY_INDEX].salaryAmount,
                employerAmount: formatRateToApi(data.salaryLines[UNCAPPED_SOCIAL_SECURITY_INDEX].employerAmount), // force to null
                selectedLine: data.salaryLines[UNCAPPED_SOCIAL_SECURITY_INDEX].selectedLine
            },
            COMPLEMENTARY_A: {
                base: data.salaryLines[COMPLEMENTARY_A_INDEX].base,
                salaryRate: formatRateToApi(data.salaryLines[COMPLEMENTARY_A_INDEX].salaryRate),
                // salaryAmount: data.salaryLines[COMPLEMENTARY_A_INDEX].salaryAmount,
                employerAmount: formatRateToApi(data.salaryLines[COMPLEMENTARY_A_INDEX].employerAmount), // force to null
                selectedLine: data.salaryLines[COMPLEMENTARY_A_INDEX].selectedLine
            },
            COMPLEMENTARY_B: {
                base: data.salaryLines[COMPLEMENTARY_B_INDEX].base,
                salaryRate: formatRateToApi(data.salaryLines[COMPLEMENTARY_B_INDEX].salaryRate),
                // salaryAmount: data.salaryLines[COMPLEMENTARY_B_INDEX].salaryAmount,
                employerAmount: formatRateToApi(data.salaryLines[COMPLEMENTARY_B_INDEX].employerAmount), // force to null
                selectedLine: data.salaryLines[COMPLEMENTARY_B_INDEX].selectedLine
            }
        },
        SOCIAL_SECURITY_FAMILY: {
            AF: {
                base: data.salaryLines[AF_INDEX].base,
                salaryRate: null,
                // salaryAmount: null,
                employerAmount: formatRateToApi(data.salaryLines[AF_INDEX].employerAmount), // force to null
                selectedLine: data.salaryLines[AF_INDEX].selectedLine
            }
        },
        UNEMPLOYMENT_INSURANCE: {
            UNEMPLOYMENT: {
                base: data.salaryLines[UNEMPLOYMENT_INDEX].base,
                salaryRate: null,
                // salaryAmount: null,
                employerAmount: formatRateToApi(data.salaryLines[UNEMPLOYMENT_INDEX].employerAmount), // force to null
                selectedLine: data.salaryLines[UNEMPLOYMENT_INDEX].selectedLine
            },
            EMPLOYEE_ASSOCIATION_FOR_GUARANTEE: {
                base: data.salaryLines[EMPLOYEE_ASSOCIATION_FOR_GUARANTEE_INDEX].base,
                salaryRate: null,
                // salaryAmount: null,
                employerAmount: formatRateToApi(data.salaryLines[EMPLOYEE_ASSOCIATION_FOR_GUARANTEE_INDEX].employerAmount), // force to null
                selectedLine: data.salaryLines[EMPLOYEE_ASSOCIATION_FOR_GUARANTEE_INDEX].selectedLine
            },
            EXECUTIVE_ASSOCIATION_EMPLOYMENT: {
                base: data.salaryLines[EXECUTIVE_ASSOCIATION_EMPLOYMENT_INDEX].base,
                salaryRate: formatRateToApi(data.salaryLines[EXECUTIVE_ASSOCIATION_EMPLOYMENT_INDEX].salaryRate),
                // salaryAmount: data.salaryLines[EXECUTIVE_ASSOCIATION_EMPLOYMENT_INDEX].salaryAmount,
                employerAmount: formatRateToApi(data.salaryLines[EXECUTIVE_ASSOCIATION_EMPLOYMENT_INDEX].employerAmount), // force to null
                selectedLine: data.salaryLines[EXECUTIVE_ASSOCIATION_EMPLOYMENT_INDEX].selectedLine
            }
        },
        GENERAL_SOCIAL_CONTRIBUTION: {
            base: data.salaryLines[GENERAL_SOCIAL_CONTRIBUTION_INDEX].base,
            salaryRate: formatRateToApi(data.salaryLines[GENERAL_SOCIAL_CONTRIBUTION_INDEX].salaryRate),
            // salaryAmount: data.salaryLines[GENERAL_SOCIAL_CONTRIBUTION_INDEX].salaryAmount,
            employerAmount: formatRateToApi(data.salaryLines[GENERAL_SOCIAL_CONTRIBUTION_INDEX].employerAmount), // force to null
            selectedLine: data.salaryLines[GENERAL_SOCIAL_CONTRIBUTION_INDEX].selectedLine
        },
        CSG_CRDS: {
            base: data.salaryLines[CSG_CRDS_INDEX].base,
            salaryRate: formatRateToApi(data.salaryLines[CSG_CRDS_INDEX].salaryRate),
            // salaryAmount: data.salaryLines[CSG_CRDS_INDEX].salaryAmount,
            employerAmount: formatRateToApi(data.salaryLines[CSG_CRDS_INDEX].employerAmount), // force to null
            selectedLine: data.salaryLines[CSG_CRDS_INDEX].selectedLine
        },
        ADESATT: {
            base: ADESATT_INDEX === -1 ? data.settings.salaryOperationMembers.ADESATT.base : data.salaryLines[ADESATT_INDEX].base,
            salaryRate: null,
            // salaryAmount: null,
            employerAmount: ADESATT_INDEX === -1 ? data.settings.salaryOperationMembers.ADESATT.employerAmount : formatRateToApi(data.salaryLines[ADESATT_INDEX].employerAmount), // force to null
            selectedLine: data.salaryLines[ADESATT_INDEX].selectedLine
        },
        STATUARY_FEE: {
            base: data.salaryLines[STATUARY_FEE_INDEX].base,
            salaryRate: null,
            // salaryAmount: null,
            employerAmount: formatRateToApi(data.salaryLines[STATUARY_FEE_INDEX].employerAmount), // force to null
            selectedLine: data.salaryLines[STATUARY_FEE_INDEX].selectedLine
        },
        DEFAULT_OTHER_DUE_EMPLOYER_CONTRIBUTION: {
            // OTHER_DUE_EMPLOYER_CONTRIBUTION: {
            //     base: data.salaryLines[OTHER_DUE_EMPLOYER_CONTRIBUTION].base,
            //     salaryRate: null,
            //     employerAmount: formatRateToApi(data.salaryLines[OTHER_DUE_EMPLOYER_CONTRIBUTION].employerAmount),
            //     selectedLine: data.salaryLines[OTHER_DUE_EMPLOYER_CONTRIBUTION].selectedLine
            // },
            AUTONOMY_SOLIDARITY_CONTRIBUTION: {
                base: data.salaryLines[AUTONOMY_SOLIDARITY_CONTRIBUTION_INDEX].base,
                salaryRate: null,
                employerAmount: formatRateToApi(data.salaryLines[AUTONOMY_SOLIDARITY_CONTRIBUTION_INDEX].employerAmount),
                selectedLine: data.salaryLines[AUTONOMY_SOLIDARITY_CONTRIBUTION_INDEX].selectedLine
            },
            UNCAPPED_FNAL: {
                base: data.salaryLines[UNCAPPED_FNAL_INDEX].base,
                salaryRate: null,
                employerAmount: formatRateToApi(data.salaryLines[UNCAPPED_FNAL_INDEX].employerAmount),
                selectedLine: data.salaryLines[UNCAPPED_FNAL_INDEX].selectedLine
            },
            MOBILITY_TRANSFER: {
                base: data.salaryLines[MOBILITY_TRANSFER_INDEX].base,
                salaryRate: null,
                employerAmount: formatRateToApi(data.salaryLines[MOBILITY_TRANSFER_INDEX].employerAmount),
                selectedLine: data.salaryLines[MOBILITY_TRANSFER_INDEX].selectedLine
            },
            SOCIAL_DIALOG_CONTRIBUTION: {
                base: data.salaryLines[SOCIAL_DIALOG_CONTRIBUTION_INDEX].base,
                salaryRate: null,
                employerAmount: formatRateToApi(data.salaryLines[SOCIAL_DIALOG_CONTRIBUTION_INDEX].employerAmount),
                selectedLine: data.salaryLines[SOCIAL_DIALOG_CONTRIBUTION_INDEX].selectedLine
            },
            PRO_FORMATION_CONTRIBUTION: {
                base: data.salaryLines[PRO_FORMATION_CONTRIBUTION_INDEX].base,
                salaryRate: null,
                employerAmount: formatRateToApi(data.salaryLines[PRO_FORMATION_CONTRIBUTION_INDEX].employerAmount),
                selectedLine: data.salaryLines[PRO_FORMATION_CONTRIBUTION_INDEX].selectedLine
            },
            CONSTRUCTION_EFFORT_PARTICIPATION: {
                base: data.salaryLines[CONSTRUCTION_EFFORT_PARTICIPATION_INDEX].base,
                salaryRate: null,
                employerAmount: formatRateToApi(data.salaryLines[CONSTRUCTION_EFFORT_PARTICIPATION_INDEX].employerAmount),
                selectedLine: data.salaryLines[CONSTRUCTION_EFFORT_PARTICIPATION_INDEX].selectedLine
            },
            LEARNING_TAXES: {
                base: data.salaryLines[LEARNING_TAXES_INDEX].base,
                salaryRate: null,
                employerAmount: formatRateToApi(data.salaryLines[LEARNING_TAXES_INDEX].employerAmount),
                selectedLine: data.salaryLines[LEARNING_TAXES_INDEX].selectedLine
            },
            OTHER_DUE_EMPLOYER_CONTRIBUTION_PROVISIONING_SOCIAL_FEE: {
                base: null,
                salaryRate: null,
                employerAmount: formatRateToApi(data.salaryLines[OTHER_DUE_EMPLOYER_CONTRIBUTION_PROVISIONING_SOCIAL_FEE].employerAmount),
                selectedLine: data.salaryLines[OTHER_DUE_EMPLOYER_CONTRIBUTION_PROVISIONING_SOCIAL_FEE].selectedLine
            }
        },
        OTHER_DUE_EMPLOYER_CONTRIBUTION: data.salaryLines
            // the default contribution ones have domain_id by default,
            // for the new ones, we don't set the domain_id
            .filter((line) => line.group === 'AUTRES CONTRIB. DUES PAR EMPL.' && !line.domain_id)
            .map((salaryLine) => {
                return {
                    ...salaryLine,
                    employerAmount: formatRateToApi(salaryLine.employerAmount)
                }
            })
    }
}
